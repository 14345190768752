$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': csrfToken
        }
    });

    $('.sodelovanje_na_drzavnem').change(function () {
        var value = $(this).val();
        if (value == 'da') {
            $('#prijavnica_drzavno_tekmovanje').removeClass('hidden');
            $('#razlog_field').addClass('hidden');
        } else {
            $('#prijavnica_drzavno_tekmovanje').addClass('hidden');
            $('#razlog_field').removeClass('hidden');
        }
    });

    $('.remove-prijava').click(function (e) {
        e.preventDefault();

        if (!confirm('Ali želite odstraniti prijavo?')) {
            return;
        }

        $.post('/prijave/odstrani', {}, function () {
            window.location.reload(true);
        });
    });

    $('.tekmovalec_1_soglasje, .tekmovalec_2_soglasje, .tekmovalec_3_soglasje').change(function () {
        var value = $(this).val();
        if (value == 'da') {
            $(this)
                .parent()
                .parent()
                .next()
                .next()
                .addClass('hidden');
        } else {
            $(this)
                .parent()
                .parent()
                .next()
                .removeClass('hidden');
        }
    });
});